<template>
  <div class="container">
    <div class="row min-vh-80 align-items-center z-index-2 position-relative text-center">
      <div class="col-12">
        <h1 class="display-2 text-white mb-4">Larisa<br>&#38;<br>Alexandru</h1>
        <p class="text-white lead mb-5">2 Iulie 2022 - Slobozia, Ialomița</p>
      </div>
    </div>
  </div>

</template>
