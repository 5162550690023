<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col">
        <div class="text-center mb-5 pb-5">
          <h1 class="display-4 mb-0">Oamenii de suflet</h1>
          <p class="w-mb-40 mb-0 mx-auto text-dark-gray opacity-8">
            Alături de care vom scrie povestea noastră
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="heart-container">
        <div class="story-icon">
          <i class="fa-solid fa-heart"></i>
        </div>
      </div>
      <div class="col-lg-12 text-center">
        <div class="mb-3 pb-3">
          <h5 class="mb-0">Nașii</h5>
          <span class="text-dark-gray opacity-8">Daniela și Alexandru Vida</span>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="heart-container">
        <div class="story-icon">
          <i class="fa-solid fa-heart"></i>
        </div>
      </div>
      <div class="col-lg-12 text-center">
        <div class="mb-3 pb-3">
          <h5 class="mb-0">Părinţii</h5>
          <div class="col-6">
          </div>
          <span class="text-dark-gray opacity-8">
            Mirela și Iulian Zaharia
            <br>
            Luminiţa și Emil Gheorghe
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
