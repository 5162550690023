<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col">
        <div class="text-center mb-5 pb-5">
          <h1 class="display-4 mb-0">Detaliile evenimentului</h1>
          <p class="w-md-40 mb-0 mx-auto text-dark-gray opacity-8">Când şi unde</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4 d-flex mb-4 mb-lg-0">
        <div class="card card-body justify-content-between text-align-center">
          <div class="mb-5 mb-md-5 events-img-wrapper">
            <img src="../../public/assets/img/certificate.jpg" alt="">
          </div>
          <h5 class="mb-0">Cununia civilă</h5>
          <h6 class="mb-5">2 Iulie 2022, 13:00</h6>
          <p class="text-align-center">
            Evergreen Events, Ponton
            <br>
            Aleea Plajei Nr. 1
            <br>
            Amara, Ialomiţa
            <br>
            <a class="btn btn-light font-weight-bold" href="https://goo.gl/maps/hfo9X2GYci2djo4GA" role="button">
              Vezi harta...
            </a>
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex mb-4 mb-lg-0">
        <div class="card card-body justify-content-between text-align-center">
          <div class="mb-5 mb-md-5 events-img-wrapper">
            <img src="../../public/assets/img/church.jpg" alt="">
          </div>
          <h5 class="mb-0">Cununia religioasă</h5>
          <h6 class="mb-5">2 Iulie 2022, 17:00</h6>
          <p class="text-align-center">
            Biserica „Naşterea Maicii Domnului”
            <br>
            Strada Mihail Kogălniceanu Nr. 57
            <br>
            Slobozia, Ialomiţa
            <br>
            <a class="btn btn-light font-weight-bold" href="https://goo.gl/maps/yR4H3AE5jfkgx46YA" role="button">
              Vezi harta...
            </a>
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex mb-4 mb-lg-0">
        <div class="card card-body justify-content-between text-align-center">
          <div class="mb-5 mb-md-5 events-img-wrapper">
            <img src="../../public/assets/img/party.jpg" alt="">
          </div>
          <h5 class="mb-0">Petrecerea</h5>
          <h6 class="mb-5">2 Iulie 2022, 19:00</h6>
          <p class="text-align-center">
            Evergreen Events, Pavilion Sole
            <br>
            Aleea Plajei Nr. 1
            <br>
            Amara, Ialomiţa
            <br>
            <a class="btn btn-light font-weight-bold" href="https://goo.gl/maps/hfo9X2GYci2djo4GA" role="button">
              Vezi harta...
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
