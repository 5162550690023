<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <a class="navbar-brand" href="#">
      <i class="fa-solid fa-heart text-white"></i>
      Larisa &#38; Alexandru
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" data-toggle="collapse"
      data-target="#navbarSupportedContent" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#despre-section">
            Despre
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#story-section">Oamenii de suflet</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#evenimente-section">Evenimentele</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#end-section">Confirmare</a>
        </li>
      </ul>
    </div>

  </nav>
</template>
